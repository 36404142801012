<template>
  <div>
      <b-tabs pills card activeTab="activeRoundTab" style="margin:10px;">
        <b-tab v-for="round in rounds" :key="round.round_number" :title="`Manche ${round.round_number}`" style="border: solid 1px darkorange;">
          <div style="display:flex; justify-content: space-between; margin:5px;">
            <div style="width:70%">
              <h4>Tour/Manche {{ round.round_number }}</h4>
              <div style="display:flex; justify-content:space-around;">
                  <b-check switch v-if="precision_available" :checked="round.has_precision" :disabled="d_isDisabled || !round.can_have_precision" @change="round_precision_change(round)">Précision</b-check>
                  <b-check switch v-if="ballet_available" :checked="round.has_ballet" :disabled="d_isDisabled || !round.can_have_ballet" @change="round_ballet_change(round)">Ballet</b-check>
                  <b-check switch v-if="mix_available" :checked="round.has_mix" :enabled="d_isDisabled || !round.can_have_mix" @change="round_mix_change(round)">MIX</b-check>
              </div> 
            </div>
            <b-button v-if="!d_isDisabled" :disabled="round.round_number < rounds.length || !round.canRemove" @click="removeRound(round)" variant="danger">Suppr.</b-button>
          </div>

          <!--<div v-if="round.has_precision || round.has_mix">
            <div Figures de précision>-->
              <event-compulsories
                v-if="round.has_precision || round.has_mix"
                :competition_id="d_competition_id"
                :level="d_level"
                :event="round.has_mix ? 'mix' : 'precision'"
                :round_number="round.round_number"
                :disabled = "d_isDisabled"
            ></event-compulsories>            
          <!--  </div>
          </div>-->
        </b-tab>
        <template #tabs-end>
          <b-nav-item v-if="!d_isDisabled" @click="addRound"> + </b-nav-item>
        </template>
    </b-tabs>
  </div>
</template>

<script>
import { RST_COMPETITION, SEASON_COMPETITION_TYPE_SCOPE_EVENT, RST_ROUND, RST_REF_EVENT } from "@/LocalDB";
//import DataSyncState from '@/components/Utilities/DataSyncState.vue';
//import FormField from '@/components/Panels/FormField.vue';
//import NumberOfRoundsEditor from '@/components/NumberOfRoundsEditor.vue';
import CompetitionsConfigurationHelper from '@/services/helpers/competitionsConfigurationHelper';
import RoundsConfigurationHelper from '@/services/helpers/roundsConfigurationHelper';
import EventCompulsories from './EventCompulsories.vue';
//import CollapsePanel from '@/components/Panels/CollapsePanel.vue';

export default {
  model:{
    prop: 'competition_id',
  },
  props:{
    competition_id: { type:String, required:true },
    level: { type:String, required:true },
    regulation: { type:String, required:true},
    disabled: {type:Boolean, default: false},
  },
  components: { /*FormField, NumberOfRoundsEditor,*/ EventCompulsories, /*DataSyncState,*/ /*CollapsePanel ,*/ },
  data(){
    return {
      VERBOSE: false,

      d_competition_id: this.competition_id,
      d_level: this.level,

      activeRoundTab: 1,
//      dataSynchronized: null,
      nbPrecision: 0,
      nbBallet: 0,
      nbMix: 0,
/*      precisionAvailable: false,
      balletAvailable: false,
      mixAvailable: false,*/
      events:[],
      eventTypes:[],
      
      precision_available: false,
      ballet_available: false,
      mix_available: false,
      rounds: [],

      d_isDisabled: this.disabled,
    }
  },
  computed:{
    eventsWithCompulsory(){
      return this.events.filter(evt => evt.EVENT.hasCompulsories);
    },
    nbPrecisionEvents:{
      get() { return this.nbPrecision;},
      set(value){ this.nbPrecision = value; }
    },
    nbBalletEvents:{
      get() { return this.nbBallet;},
      set(value){ this.nbBallet = value; }
    },
    nbMixEvents:{
      get() { return this.nbMix;},
      set(value){ this.nbMix = value; }
    },
/*    minPrecisionEvents(){ return 0; },
    minBalletEvents() { return 0; },
    minMixEvents() { return 0; },
    maxPrecisionEvents(){ return 3; },
    maxBalletEvents(){ return 3; },
    maxMixEvents(){ return 3; },*/
  },
  methods:{
    async refresh(){
      await this.$showRefresher();
      var dataSynchronized = await CompetitionsConfigurationHelper.refreshEvents(this.competition_id);
      var events = RST_REF_EVENT.query().where('competition_id', this.d_competition_id).where("level", this.d_level).get();
      
      var COMPET = RST_COMPETITION.query().where('id', this.d_competition_id).first();
      var EVENTS_TYPES = SEASON_COMPETITION_TYPE_SCOPE_EVENT.query()
        .where('competition_type', COMPET.competition_type)
        .where('year', COMPET.year)
        .where('scope', COMPET.scope)
        .orderBy('displayOrder')
        .get();

      this.precision_available = EVENTS_TYPES.some(e => e.event == 'precision');
      this.ballet_available = EVENTS_TYPES.some(e => e.event == 'ballet');
      this.mix_available = EVENTS_TYPES.some(e => e.event == 'mix');

      var roundsQuery = RST_ROUND.query()
        .where('competition_id', this.d_competition_id)
        .where('level', this.d_level);
      if(!roundsQuery.exists())
        roundsQuery = RST_ROUND.query()
          .where('competition_id', this.d_competition_id)
          .where(r => r.level != this.d_level);
      if(roundsQuery.exists())
        this.rounds = roundsQuery
            .orderBy('round_number')
            .get()
            .map(r => { 
              var ret = {...r,
                      level: this.d_level, 
                      has_precision: events.some(e => e.round_number == r.round_number && e.event == 'precision'), 
                      has_ballet: events.some(e => e.round_number == r.round_number && e.event == 'ballet'), 
                      has_mix: events.some(e => e.round_number == r.round_number && e.event == 'mix'),
                      canRemove: !r.isBriefingStarted,
                    };
              ret.can_have_precision = ret.has_precision ? !ret.isBriefingStarted : this.precision_available && !ret.isBriefingCompleted &&!ret.has_mix;
              ret.can_have_ballet = ret.has_ballet ? !ret.isBriefingStarted : this.ballet_available && !ret.isBriefingCompleted &&!ret.has_mix;
              ret.can_have_mix = ret.has_mix ? !ret.isBriefindStarted : this.mix_availbale && !ret.isBriefingCompleted && !(ret.has_precision || ret.has_ballet);
              return ret;
            })
      else
        this.rounds = [];

      if(this.VERBOSE) console.log('refresh', { COMPET: COMPET, EVENTS_TYPES: EVENTS_TYPES, precision_available: this.precision_available, ballet_available: this.ballet_available, mix_available: this.mix_available, rounds: this.rounds});
/*
      this.eventTypes = EVENTS.map(evtType => {
            var level_type_events = this.events.filter(evt => evt.event == evtType.event);
            var started = level_type_events.filter(evt => evt.isStarted || evt.isCompleted).length;
            var total = level_type_events.length;
            return {...evtType.EVENT,
              nbMinEvents: started,
              nbEvents: total,
              nbMaxEvents: 3
            };
          });
*/
      this.$hideRefresher();
      this.$emit("dataSynchronisationStateChange", dataSynchronized);
    },
    async addRound(){
      await this.$showSaver();
      try{
        var result = await RoundsConfigurationHelper.createRoundAsync(this.d_competition_id);
        this.$hideSaver();
        this.toastAsyncSaved(result.success, `Manche ${result.round.round_number} ajoutée`);
        this.$emit('RoundsChange')
        await this.refresh();
        this.activeRoundTab = result.round.round_number;
      }catch(error){
        this.$hideSaver();
        this.toastSaveFailure("Echec de la création de la manche : " + error);
      }
    },
    async removeRound(round){
      await this.$showSaver();
      try{
        var result = await RoundsConfigurationHelper.deleteRoundAsync(this.d_competition_id, round.round_number);
        this.$hideSaver();
        this.toastAsyncSaved(result, `Manche ${round.round_number} supprimée !`);
        this.$emit('RoundsChange');
        await this.refresh();
        this.activeRoundTab = this.rounds.length;
      } catch(error){
        this.$hideSaver();
        this.toastSaveFailure(`Echec de la suppression de la manche ${round.round_number} : ${error}.`);
      }
    },
    async round_precision_change(round){
      await this.$showSaver();
      try{
        var result = round.has_precision 
                   ? await RoundsConfigurationHelper.deleteRefEventAsync(this.d_competition_id, round.round_number, 'precision', this.d_level)
                   : await RoundsConfigurationHelper.createRefEventAsync(this.d_competition_id, round.round_number, 'precision', this.d_level);
        
        round.has_precision = !round.has_precision;
        this.toastAsyncSaved(result, round.has_precision ? "Epreuve de précision ajoutée" : "Epreuve de précision supprimée");
        this.$emit('EventsChange');
        await this.refresh();
      } catch (error){
        this.toastSaveFailure(round.has_precision ? "Echec de la suppression de l'épreuve de précision : " + error : "Echec de l'ajout de l'épreuve de précision : " + error);
      }
      this.$hideSaver();
    },
    async round_ballet_change(round){
      await this.$showSaver();
      try{
        var result = round.has_ballet 
                   ? await RoundsConfigurationHelper.deleteRefEventAsync(this.d_competition_id, round.round_number, 'ballet', this.d_level)
                   : await RoundsConfigurationHelper.createRefEventAsync(this.d_competition_id, round.round_number, 'ballet', this.d_level);
        this.toastAsyncSaved(result, round.has_ballet ? "Epreuve de ballet ajoutée" : "Epreuve de ballet supprimée");
        round.has_ballet = !round.has_ballet;
        this.$emit('EventsChange');
        await this.refresh();
      } catch (error){
        this.toastSaveFailure(round.has_ballet ? "Echec de la suppression de l'épreuve de ballet : " + error : "Echec de l'ajout de l'épreuve de ballet : " + error);
      }
      this.$hideSaver();
    },
    async round_mix_change(round){
      await this.$showSaver();
      try{
        var result = round.has_mix 
                   ? await RoundsConfigurationHelper.deleteRefEventAsync(this.d_competition_id, round.round_number, 'mix', this.d_level)
                   : await RoundsConfigurationHelper.createRefEventAsync(this.d_competition_id, round.round_number, 'mix', this.d_level);
        this.toastAsyncSaved(result, round.has_mix ? "Epreuve mixte ajoutée" : "Epreuve mixte supprimée");
        round.has_mix = !round.has_mix;
        this.$emit('EventsChange');
        await this.refresh();
      } catch (error){
        this.toastSaveFailure(round.has_mix ? "Echec de la suppression de l'épreuve mixte : " + error : "Echec de l'ajout de l'épreuve mixte : " + error);
      }
      this.$hideSaver();
    },
    async addEvent(eventType, displayOrder){
      //console.log('addEvent', eventType, this.d_level, displayOrder);
      await this.$showSaver();
      try{
        var result = await CompetitionsConfigurationHelper.createEventAsync(this.d_competition_id, eventType, this.d_level, displayOrder);
        this.toastAsyncSaved(result, "Epreuve ajoutée");
        this.$emit('EventsChange');
        await this.refresh();
      } catch (error)
      {
        this.toastSaveFailure("Echec de la création de l'épreuve : " + error);
      }
      this.$hideSaver();
    },
    async removeEvent(eventType){
      if(this.VERBOSE) console.log('removeEvent', eventType, this.d_level);
      await this.$showSaver();
      try{
        var result = await CompetitionsConfigurationHelper.deleteEventAsync(this.d_competition_id, eventType, this.d_level);
        this.toastAsyncSaved(result, "Epreuve supprimée");
        this.$emit('EventsChange');
        await this.refresh();
      } catch (error)
      {
        this.toastSaveFailure("Echec de la suppression de l'épreuve : " + error);
      }
      this.$hideSaver();
    }
  },
  async mounted(){
    //console.log('EventsAdminFrCdfVa mounted', this.competition_id, this.d_competition_id);
    await this.refresh();
  },
  watch:{
    competition_id: function(newVal){
      this.d_competition_id = newVal;
      this.refresh();
    },
    level: function(newVal){
      this.d_level = newVal;
      this.refresh();
    },
    disabled: function(newVal){
      this.d_isDisabled = newVal;
    }
  }
}
</script>
